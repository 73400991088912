<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('list.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('list.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
          </b-col>
        </b-row>
      </div>
      <vue-double-scrollbar v-if="tableDataLoaded">
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="items"
          :fields="tableColumns"
          primary-key="identity"
          show-empty
          empty-text="No matching records found">
          <!-- Column: names -->
          <template #cell(firt_name)="data">
            {{ data.item.firt_name }} {{ data.item.middle_name }}
          </template>
          <!-- Column: surnames -->
          <template #cell(surname_1)="data">
            {{ data.item.surname_1 }} {{ data.item.surname_2 }}
          </template>
          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize">
              {{ resolveUserStatusMessage(data.item.status) }}
            </b-badge>
          </template>
          <!-- Column: actions -->
          <template #cell(actions)="data">
            <b-col class="flex flex-row flex-wrap">
              <b-button
                :id="get_view_button_id(data.item.id)"
                style="margin: 1px"
                variant="primary"
                :to="{
                  name: 'apps-availabilities-view',
                  params: { id: data.item.id },
                }">
                <feather-icon icon="FileTextIcon" />
              </b-button>
              <b-button
                :id="get_edit_button_id(data.item.id)"
                style="margin: 1px"
                variant="primary"
                :to="{
                  name: 'apps-availabilities-edit',
                  params: { id: data.item.id },
                }">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                :id="get_download_button_id(data.item.id)"
                style="margin: 1px"
                variant="primary"
                @click="export2excel(data.item.id)">
                <feather-icon icon="DownloadIcon" />
              </b-button>
              <!-- ? VIEW -->
              <b-tooltip
                :target="get_view_button_id(data.item.id)"
                :title="$t('actions.details')"
                triggers="hover"
                :noninteractive="true"
                placement="top">
              </b-tooltip>
              <!-- ? EDIT -->
              <b-tooltip
                :target="get_edit_button_id(data.item.id)"
                :title="$t('actions.edit')"
                triggers="hover"
                :noninteractive="true"
                placement="top">
              </b-tooltip>
              <!-- ? EXPORT -->
              <b-tooltip
                :target="get_download_button_id(data.item.id)"
                :title="$t('actions.export')"
                triggers="hover"
                :noninteractive="true"
                placement="top">
              </b-tooltip>
            </b-col>
          </template>
        </b-table>
      </vue-double-scrollbar>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="6"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import store from '@/store'
import * as XLSX from 'xlsx'
import Filters from './filters.vue'
import ElementStoreModule from '../storeModule'

export default {
  components: {
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      currentPath: window.location.pathname,
      tableColumns: [
        { key: 'firt_name', label: this.$t('user.names'), sortable: true },
        { key: 'surname_1', label: this.$t('user.surnames'), sortable: true },
        { key: 'document', label: this.$t('user.identity'), sortable: true },
        { key: 'email', label: this.$t('user.email'), sortable: true },
        { key: 'status', label: this.$t('user.status'), sortable: true },
        { key: 'actions', label: this.$t('actions.tittle') },
      ],
      sheets: [{ name: 'SheetOne', data: [{ c: 2 }] }],
      ELEMENT_APP_STORE_MODULE_NAME: 'app-availabilities',
      tableDataLoaded: false,
    }
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.currentPage = 1
      if (val.length >= 3 || val.length == 0) this.fetchElements()
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    var data = JSON.parse(sessionStorage.getItem(this.currentPath))

    data && (this.query = data.query)

    this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    get_view_button_id(key) {
      return `view_button${key}`
    },
    get_edit_button_id(key) {
      return `edit_button${key}`
    },
    get_download_button_id(key) {
      return `download_button${key}`
    },
    filterJson(jsonData, tableColumns) {
      const filterList = tableColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    getHeader(tableColumns) {
      const res = {}
      for (let i = 0; i < tableColumns.length; i++) {
        res[tableColumns[i].key] = tableColumns[i].label
      }
      return res
    },
    format(jsonData) {
      for (let i = 0; i < jsonData.length; i++) {
        jsonData[i].calendar_date = moment(jsonData[i].calendar_date).format(
          'DD/MM/YYYY'
        )
      }
    },
    async export2excel(id) {
      const tableColumns = [
        {
          key: 'calendar_date',
          label: this.$t('availabilities.object.calendar_date'),
        },
        {
          key: 'initial_availability_str',
          label: this.$t('availabilities.object.initial_availability'),
        },
        {
          key: 'final_availability_str',
          label: this.$t('availabilities.object.final_availability'),
        },
        {
          key: 'initial_unavailability_str',
          label: this.$t('availabilities.object.initial_unavailability'),
        },
        {
          key: 'final_unavailability_str',
          label: this.$t('availabilities.object.final_unavailability'),
        },
        {
          key: 'observations',
          label: this.$t('availabilities.object.observations'),
        },
        {},
      ]
      const filename = this.$t('availabilities.object_name')
      const result = await store.dispatch(
        `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchDataForExcel`,
        { id }
      )
      this.format(result.data)
      result.data.unshift(this.getHeader(tableColumns))
      const list = this.filterJson(result.data, tableColumns)
      const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    beforeGenerate() {
      console.info('test')
    },
    fetchElements() {
      sessionStorage.setItem(
        this.currentPath,
        JSON.stringify({ query: this.query })
      )

      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElements`, {
          perPage: this.perPage,
          page: this.currentPage,
          query: this.query,
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
          this.tableDataLoaded = true
        })
        .catch((err) => {
          console.error(err)
        })
    },
    resolveUserStatusVariant(status) {
      if (status === 0) return 'warning'
      return 'success'
    },
    resolveUserStatusMessage(status) {
      if (status === 0) return this.$t('user.statusValue.inactive')
      return this.$t('user.statusValue.active')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
